<script script setup>
const props = defineProps(["singleBible", "items"]);
const emit = defineEmits(["change"]);

const oldTestColl = computed(() => props.items?.slice(0, 39));
const newTestColl = computed(() => props.items?.slice(39));

const isSelected = (book) => {
  return props.singleBible?.location?.bookModel?.bookCode == book.bookCode;
};

function handleSelectBook(book) {
  emit("change", {
    book,
    singleBible: props.singleBible,
  });
}
</script>
<template>
  <div class="select-book mx-4">
    <div class="left">
      <div class="title">{{ transl("Old testament") }}</div>

      <div
        v-for="book in oldTestColl"
        @click="handleSelectBook(book)"
        :class="{ active: isSelected(book) }"
      >
        {{ book?.bookNameCanonLong }}
      </div>
    </div>
    <div class="right">
      <div class="title">{{ transl("New testament") }}</div>
      <div
        v-for="book in newTestColl"
        @click="handleSelectBook(book)"
        :class="{ active: isSelected(book) }"
      >
        {{ book?.bookNameCanonLong }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-btn {
  color: #292937;
  font-family: Golos Text;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.active {
  background: var(--root-green);
  color: var(--root-white);
  cursor: pointer;
}

.select-book {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 140px;
}

.select-book .title {
  font-weight: 600;
  font-size: 19px;
  padding: 0;
  padding: 0px 0 5px 0;
  line-height: 40px;
  border-radius: 0;
}

.select-book div {
  min-height: 50px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0px 8px 0px 8px;
  margin-left: -6px;
  line-height: 50px;
  text-wrap: nowrap;
}

.select-book div div:hover {
  background: var(--root-green);
  color: var(--root-white);
  cursor: pointer;
}
@media (max-width: 400px) {
  .select-book div {
    font-size: 13bible-translpx;
    line-height: 40px;
    min-height: 40px;
  }
}

/* .select-book div .title:hover {
  background: transparent;
  color: black;
  cursor: inherit;
} */

.select-book .title {
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: solid 1px #ddd;
}
.select-book .title:hover {
  background: #fff;
  color: var(--text-sub-color);
}
</style>
